var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.rules,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","loading":_vm.loading,"dense":"","item-class":function () { return 'cursor-pointer'; }},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.min_freight",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$functions.money(item.min_freight)))])]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.percentage)+"%")])]}},{key:"item.icms",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.icms ? 'Sim' : 'Não'))])]}},{key:"item.toll_discount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.toll_discount ? 'Sim' : 'Não'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":_vm.loading,"color":"error"},on:{"click":function (e) { e.stopPropagation(); _vm.deleteRule(item.id) }}},[_vm._v("mdi-delete")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }