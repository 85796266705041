<template>
  <div>
    <h1>Regras de Coletas</h1>

    <v-btn
      color="success"
      @click="$router.push({name: 'collectRules.create'})"
      class="ma-2"
      title="Adicionar Regra"
      fab
      small
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn
      color="error"
      @click="adding = false"
      class="ma-2"
      title="Voltar"
      fab
      small
    >
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <ShowCollectRules ref="showCollectRulesComponent" />
  </div>
</template>

<script>

import ShowCollectRules from '../components/ShowCollectRules.vue';

  export default {
    components: {
      ShowCollectRules,
    },
    methods: {
      handleCreated(rule) {
        this.$refs.showCollectRulesComponent.addCollectRule(rule);
      }
    },
  }
</script>