<template>
  <div>
    <v-data-table
      :items="rules"
      :headers="headers"
      disable-pagination
      hide-default-footer
      :loading="loading"
      dense
      @click:row="handleClick"
      :item-class="() => 'cursor-pointer'"
    >
      <template v-slot:[`item.min_freight`]="{ item }">
        <span>{{ $functions.money(item.min_freight) }}</span>
      </template>

      <template v-slot:[`item.percentage`]="{ item }">
        <span>{{ item.percentage }}%</span>
      </template>

      <template v-slot:[`item.icms`]="{ item }">
        <span>{{ item.icms ? 'Sim' : 'Não' }}</span>
      </template>

      <template v-slot:[`item.toll_discount`]="{ item }">
        <span>{{ item.toll_discount ? 'Sim' : 'Não' }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="(e) => { e.stopPropagation(); deleteRule(item.id) }" :disabled="loading" color="error">mdi-delete</v-icon>
      </template>
      
    </v-data-table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        rules: [],
        headers: [
          {text: 'Parceira', value: 'partners.name'},
          {text: 'Mínimo', value: 'min_freight'},
          {text: 'Porcentagem', value: 'percentage'},
          {text: 'ICMS', value: 'icms'},
          {text: 'Pedágio', value: 'toll_discount'},
          {text: 'Ações', value: 'actions'},
        ]
      }
    },
    mounted () {
      this.getCollectRules();
    },
    methods: {
      handleClick(event) {
        if(!event.id) {
          return;
        }

        this.$router.push({ name: 'collectRules.create', params: { id: event.id } });
      },

      addCollectRule(rule) {
        this.rules.push(rule);
      },

      async deleteRule(id) {
        if(confirm('Tem certeza que deseja excluir esta regra?')) {
          this.loading = true;

          try {
            await this.$http.delete(`api/collect-rules/${id}`);
            
            this.$toast.success('Removido com sucesso');
            this.getCollectRules();
          } catch (err) {
            console.log(err);
            this.$toast.error('Erro ao remover');
          }

          this.loading = false;
        }
      },
      getCollectRules() {
        this.loading = true;
        this.$http.get('api/collect-rules')
        .then(res => {
          this.loading = false;
          this.rules = res.data;
        }).catch(err => {
          this.loading = false;
          console.log(err);
          this.$toast.error('Erro ao carregar regras');
        });
      }
    },
  }
</script>